// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// MODEL
import { Cart, Order, CheckoutInfo, GSTConfig } from 'model';

// ROUTER
import { CartApi } from 'constant';

// TYPE
import { Callback } from 'interface';

// ACTIONS
import { setOrder } from 'redux/order/action';

// SLICE
import { cartSlice } from './slice';

const { changeCartLoading, setCart, setCartCheckoutInfo, setGSTConfig } = cartSlice.actions;

const saveCart =
  (cart: Partial<Cart>, callback?: Callback<boolean>): AppThunk =>
  async (dispatch) => {
    dispatch(changeCartLoading(true));

    dispatch(
      httpAction<Partial<Cart>, unknown, Cart>(
        {
          method: 'PUT',
          url: CartApi.cart,
          data: { ...cart, marketingConsent: cart.marketingConsent, source: 'MYNH' },
          needAuth: true
        },
        async (res) => {
          dispatch(changeCartLoading(false));

          if (res.data) {
            dispatch(setCart(res.data));
          }

          if (callback) {
            callback(!!res.data);
          }
        }
      )
    );
  };

const cartCheckout =
  (callback?: Callback<Order>): AppThunk =>
  async (dispatch) => {
    dispatch(changeCartLoading(true));

    dispatch(
      httpAction<unknown, unknown, Order>(
        {
          method: 'POST',
          url: CartApi.checkout,
          needAuth: true
        },
        async (res) => {
          dispatch(changeCartLoading(false));

          if (res.data) {
            dispatch(setOrder(res.data));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const removePromotion =
  (callback?: Callback<CheckoutInfo>): AppThunk =>
  async (dispatch) => {
    dispatch(changeCartLoading(true));

    dispatch(
      httpAction<unknown, unknown, CheckoutInfo>(
        {
          method: 'PUT',
          url: CartApi.removePromotion,
          needAuth: true
        },
        async (res) => {
          dispatch(changeCartLoading(false));

          if (res.data) {
            dispatch(
              setCartCheckoutInfo({
                ...res.data,
                items: res.data.items.map((v) => {
                  const result = { ...v };

                  if (v.discount) {
                    return {
                      ...result,
                      discount: {
                        description: `Less - ${v.discount.description}`,
                        amount: v.discount.amount
                      }
                    };
                  }

                  return result;
                })
              })
            );
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const deleteCartCheckout =
  (callback?: Callback<boolean>): AppThunk =>
  async (dispatch) => {
    dispatch(changeCartLoading(true));

    dispatch(
      httpAction<unknown, unknown, boolean>(
        {
          method: 'DELETE',
          url: CartApi.cart,
          needAuth: true
        },
        async (res) => {
          dispatch(changeCartLoading(false));

          if (res.httpCode === 200) {
            dispatch(setCartCheckoutInfo());
          }

          if (callback) {
            callback(res.httpCode === 200);
          }
        }
      )
    );
  };

const getCheckoutInfo =
  (callback?: Callback<CheckoutInfo>, noReduxUpdate?: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(changeCartLoading(true));

    dispatch(
      httpAction<unknown, unknown, CheckoutInfo>(
        {
          method: 'GET',
          url: CartApi.getCheckoutInfo,
          needAuth: true
        },
        async (res) => {
          dispatch(changeCartLoading(false));

          if (res.data && !noReduxUpdate) {
            dispatch(
              setCartCheckoutInfo({
                ...res.data,
                items: res.data.items.map((v) => {
                  const result = { ...v };

                  if (v.discount) {
                    return {
                      ...result,
                      discount: {
                        description: `Less - ${v.discount.description}`,
                        amount: v.discount.amount
                      }
                    };
                  }

                  return result;
                })
              })
            );
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const applyPromotion =
  (coupon: string, callback?: Callback<CheckoutInfo>): AppThunk =>
  async (dispatch) => {
    dispatch(changeCartLoading(true));

    dispatch(
      httpAction<{ coupon: string }, unknown, CheckoutInfo>(
        {
          method: 'PUT',
          url: CartApi.applyPromotion,
          data: { coupon },
          needAuth: true
        },
        async (res) => {
          dispatch(changeCartLoading(false));

          if (res.data) {
            dispatch(
              setCartCheckoutInfo({
                ...res.data,
                items: res.data.items.map((v) => {
                  const result = { ...v };

                  if (v.discount) {
                    return {
                      ...result,
                      discount: {
                        description: `Less - ${v.discount.description}`,
                        amount: v.discount.amount
                      }
                    };
                  }

                  return result;
                })
              })
            );
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const getGSTConfig =
  (date: string, callback?: Callback<GSTConfig>): AppThunk =>
  async (dispatch) => {
    dispatch(changeCartLoading(true));

    dispatch(
      httpAction<unknown, unknown, GSTConfig>(
        {
          method: 'GET',
          url: CartApi.getGSTConfig(date),
          needAuth: true
        },
        async (res) => {
          dispatch(changeCartLoading(false));

          if (res.data) {
            dispatch(setGSTConfig(res.data));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

export {
  getCheckoutInfo,
  cartCheckout,
  saveCart,
  setCartCheckoutInfo,
  deleteCartCheckout,
  applyPromotion,
  removePromotion,
  getGSTConfig
};
